<template>
  <el-container>
    <el-aside><Aside></Aside></el-aside>
    <el-container>
      <el-header><Header></Header></el-header>
      <el-main>
        <router-view v-slot="{ Component }">
          <transition mode="out-in"
          :enter-active-class="enterClass"
          :leave-active-class="leaveClass">
            <component class="animate__animated" :is="Component" />
          </transition>
        </router-view>
      </el-main>
      <el-footer><Footer></Footer></el-footer>
    </el-container>
  </el-container>
</template>

<script>
import Header from './components/Header.vue'
import Aside from './components/Aside.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Aside,
    Footer
  },
  data() {
    return {
      enterClass: '',
      leaveClass: ''
    };
  },
  watch: {
    $route(to, from){
      if(to.meta.index >= from.meta.index){
        this.leaveClass='animate__fadeOutUp'
        this.enterClass='animate__fadeInUp'
        // this.leaveClass='animate__fadeOut'
        // this.enterClass='animate__fadeIn'
      }else{
        this.leaveClass='animate__fadeOutDown'
        this.enterClass='animate__fadeInDown'
        // this.leaveClass='animate__fadeOut'
        // this.enterClass='animate__fadeIn'
      }
    }
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: #2c3e50;
}
.el-container {
  height: 100%;
}
.el-aside {
  width: 181px !important;
  height: 100% !important;
}
.el-header {
  padding: 0px !important;
}
.el-main {
  padding: 8px !important;
}
</style>
