import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Order from '../views/Order.vue'
import GoodsManage from '../views/GoodsManage.vue'
import FinanceManage from '../views/FinanceManage.vue'
import DataAnalysis from '../views/DataAnalysis.vue'
import AdminManage from '../views/AdminManage.vue'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      index: 0
    }
  },
  {
    path: '/order',
    name: 'Order',
    component: Order,
    meta: {
      index: 1
    }
  },
  {
    path: '/goodsManage',
    name: 'GoodsManage',
    component: GoodsManage,
    meta: {
      index: 2
    }
  },
  {
    path: '/financeManage',
    name: 'FinanceManage',
    component: FinanceManage,
    meta: {
      index: 3
    }
  },
  {
    path: '/dataAnalysis',
    name: 'DataAnalysis',
    component: DataAnalysis,
    meta: {
      index: 4
    }
  },
  {
    path: '/adminManage',
    name: 'AdminManage',
    component: AdminManage,
    meta: {
      index: 5
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
