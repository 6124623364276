<template>
  <div class="admin-manage">
    <h1>操作员管理</h1>
    <el-button type="primary" @click="add()">添加</el-button>
    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="date"
        label="日期"
        width="180">
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        width="180">
      </el-table-column>
      <el-table-column
        prop="address"
        label="地址"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  name: 'AdminManage',
  data() {
    return {
      tableData: [],
      index: 0
    }
  },
  methods: {
    add() {
      var id = this.index + 1
      this.index += 1;
      this.tableData.push({
        id: id,
        date: '2016-05-02',
        name: '王小虎'+id,
        address: '上海市普陀区金沙江路 1518 弄'
      })
    },
    handleDelete(index) {
      this.tableData.splice(index, 1)
    }
  }
}
</script>