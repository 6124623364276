<template>
    <el-menu :default-active="activeIndex" mode="horizontal" @select="selectHandle" 
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b">
        <div class="left-group">
            <el-menu-item index="0">
                Logo
            </el-menu-item>
        </div>
        <div class="right-group">
            <el-menu-item index="1">
                <template #title>
                    <i class="el-icon-s-claim"></i>
                    <span>待办</span>
                </template>
            </el-menu-item>
            <el-menu-item index="2">
                <template #title>
                    <i class="el-icon-message-solid"></i>
                    <span>消息</span>
                </template>
            </el-menu-item>
            <el-menu-item index="3">
                <template #title>
                    <i class="el-icon-user-solid"></i>
                    <span>用户</span>
                </template>
            </el-menu-item>
        </div>
    </el-menu>
</template>

<script>
export default {
    name: "Header",
    setup() {
        return {
            activeIndex: '0',
        };
    },
    methods:{
        selectHandle(key, keyPath){
            console.log(key, keyPath);
        }
    }
}
</script>

<style scoped>
[class ^= "el-icon-"]{
    color: #fff;
}
.left-group {
    float: left;
    width: auto;
}
.right-group {
    float: right;
    width: auto;
}
.el-menu-item {
    float: left;
}
</style>