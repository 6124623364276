<template>
    <el-menu :default-active="$route.path" router mode="vertical"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b">
        <el-menu-item index="/home">
            <template #title>
                <i class="el-icon-s-home"></i>
                <span>首页</span>
            </template>
        </el-menu-item>
        <el-menu-item index="/order">
            <template #title>
                <i class="el-icon-s-order"></i>
                <span>订单管理</span>
            </template>
        </el-menu-item>
        <el-menu-item index="/goodsManage">
            <template #title>
                <i class="el-icon-s-goods"></i>
                <span>商品管理</span>
            </template>
        </el-menu-item>
        <el-menu-item index="/financeManage">
            <template #title>
                <i class="el-icon-s-finance"></i>
                <span>财务管理</span>
            </template>
        </el-menu-item>
        <el-menu-item index="/dataAnalysis">
            <template #title>
                <i class="el-icon-s-marketing"></i>
                <span>数据分析</span>
            </template>
        </el-menu-item>
        <el-menu-item index="/adminManage">
            <template #title>
                <i class="el-icon-s-tools"></i>
                <span>操作员管理</span>
            </template>
        </el-menu-item>
    </el-menu>
</template>

<script>
export default {
    name: "Aside",
    setup() {
        return {
            
        }
    },
    methods:{
        
    }
}
</script>

<style scoped>
[class ^= "el-icon-"]{
    color: #fff;
}
.el-menu{
    width: 180px;
    height: 100%;
}
</style>