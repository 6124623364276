<template>
  <div class="order">
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane name="all">
        <template #label>
          <span>全部订单<el-badge type="primary" :value="12" /></span>
        </template>
        <el-button type="primary" @click="add()">添加</el-button>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            prop="date"
            label="日期"
            width="180">
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="180">
          </el-table-column>
          <el-table-column
            prop="address"
            label="地址"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane name="waitPay">
        <template #label>
          <span>待付款<el-badge type="warning" :value="2" /></span>
        </template>
        待付款
      </el-tab-pane>
      <el-tab-pane name="waitShipment">
        <template #label>
          <span>代发货<el-badge type="warning" :value="4" /></span>
        </template>
        代发货
      </el-tab-pane>
      <el-tab-pane name="waitSignIn">
        <template #label>
          <span>待签收<el-badge type="danger" :value="3" /></span>
        </template>
        待签收
      </el-tab-pane>
      <el-tab-pane name="complete">
        <template #label>
          <span>已完成<el-badge type="success" :value="3" /></span>
        </template>
        已完成
      </el-tab-pane>
    </el-tabs>
    
  </div>
</template>

<script>

export default {
  name: 'Order',
  data() {
    return {
      tableData: [],
      index: 0,
      activeName: 'all'
    }
  },
  methods: {
    add() {
      var id = this.index + 1
      this.index += 1;
      this.tableData.push({
        id: id,
        date: '2016-05-02',
        name: '王小虎'+id,
        address: '上海市普陀区金沙江路 1518 弄'
      })
    },
    handleDelete(index) {
      this.tableData.splice(index, 1)
    }
  }
}
</script>

<style scoped>

</style>