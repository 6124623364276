<template>
  <div align="center">
    <el-link href="https://beian.miit.gov.cn" target="_blank">豫ICP备2021018143号-1</el-link>
  </div>
</template>

<script>
export default {
    name: "Footer",
    setup() {
        return {
            
        };
    },
    methods:{
        
    }
}
</script>

<style scoped>

</style>