<template>
  <div class="home">
    <el-space wrap>
      <div id="home-card1" class="home-card">
        <span>今日访客数</span>
      </div>
      <div id="home-card2" class="home-card">
        <span>今日订单量</span>
      </div>
      <div id="home-card3" class="home-card">
        <span>今日退货量</span>
      </div>
      <div id="home-card4" class="home-card">
        <span>今日订单收入</span>
      </div>
    </el-space>
    <el-space wrap>
      <div id="left-view" class="left-card"></div>
      <div id="right-view" class="right-card">
        <el-space direction="vertical" style="width:100%">
          <div>{{dateStr}} {{weekStr}}</div>
          <div>{{timeStr}}</div>
        </el-space>
      </div>
    </el-space>
  </div>
</template>

<script>
const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);
export default {
  name: 'Home',
  data() {
    return {
      leftViewChart: null,
      dateStr: "",
      timeStr: "",
      weekStr: "",
      timer: null,
      unit: ['周日','周一','周二','周三','周四','周五','周六']
    }
  },
  mounted() {
    this.drawLeftView()
    this.getData()
    this.setTimer()
  },
  methods: {
    drawLeftView() {
      this.leftViewChart = this.$echarts.init(document.getElementById("left-view"))
      // 绘制图表
      unwarp(this.leftViewChart).setOption({
        title: {
          text: "访客数与订单量趋势图",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {show: true}
          }
        },
        legend: {
          top: 'bottom',
          data:['订单量','访客数']
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {type: 'value'},
        series: [
          {
            name: "订单量",
            type: "line",
            // stack: '总量',
            areaStyle: {},
            smooth: true,
            emphasis: {
                focus: 'series'
            },
            data: []
          },
          {
            name: "访客数",
            type: "line",
            // stack: '总量',
            areaStyle: {},
            smooth: true,
            emphasis: {
                focus: 'series'
            },
            data: []
          }
        ],
      });
    },
    randomNum(min = 0, max = 100) {
      return Math.floor(Math.random()*(max-min+1)+min)
    },
    getData() {
      this.leftViewChart.showLoading()
      setTimeout(() => {
        var xAxisData = new Array(13)
        var data1 = new Array(13)
        var data2 = new Array(13)
        for(var i = 0; i < 13; i++){
          xAxisData[i] = i * 2 + ':00'
          data1[i] = this.randomNum(350, 380)
          data2[i] = data1[i] + this.randomNum(80, 120)
        }
        unwarp(this.leftViewChart).setOption({
          xAxis: {
            data: xAxisData
          },
          series: [
            {
              data: data1
            },
            {
              data: data2
            }
          ],
        })
        this.leftViewChart.hideLoading()
      }, 1000);
    },
    setTimer(){
      if(this.timer == null){
        this.timer = setInterval(() => {
          this.formatDate()
        }, 20)
      }
    },
    formatDate(){
      var now = new Date()
      this.dateStr = this.$moment(now).format('YYYY年MM月DD日')
      this.timeStr = this.$moment(now).format('HH:mm:ss')
      this.weekStr = this.unit[this.$moment(now).weekday()]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../base-scss';
.home-card {
  @extend .my-shadow;
  margin: 8px;
  width: 275px;
  height: 110px;
  padding: 8px;
}
#home-card1 {
  background-image: linear-gradient(to right, rgb(253,111,163) , rgb(255,179,124));
}
#home-card2 {
  background-image: linear-gradient(to right, rgb(141,117,239) , rgb(71,192,232));
}
#home-card3 {
  background-image: linear-gradient(to right, rgb(36,148,151) , rgb(93,237,189));
}
#home-card4 {
  background-image: linear-gradient(to right, rgb(144,86,250) , rgb(206,87,239));
}
</style>