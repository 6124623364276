import { createApp } from 'vue'
import App from './App.vue'
import installElementPlus from './plugins/element'
import router from './router'
import animate from 'animate.css'
import Navigation from 'vue-navigation'
import * as echarts from 'echarts'
import moment from 'moment'

const app = createApp(App).use(animate).use(Navigation).use(router)
installElementPlus(app)
app.config.globalProperties.$echarts = echarts
app.config.globalProperties.$moment = moment
app.mount('#app')