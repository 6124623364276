<template>
  <div class="data-analysis">
    <h1>数据分析</h1>
    <el-space wrap>
      <div id="echarts_1" class="left-card"></div>
      <div id="echarts_2" class="right-card">
        <span>图表2</span>
      </div>
    </el-space>
  </div>
</template>

<script>
const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);
export default {
  name: "DataAnalysis",
  data() {
    return {
      myChart: null,
    };
  },
  mounted() {
    this.drawLine();
    this.getData();
  },
  methods: {
    drawLine() {
      this.myChart = this.$echarts.init(document.getElementById("echarts_1"))
      // 绘制图表
      unwarp(this.myChart).setOption({
        title: {
          text: "ECharts示例",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          top: 'bottom',
          orient: 'horizontal',
          data: ["销量","预估销量","趋势"],
        },
        xAxis: {
          data: [],
        },
        yAxis: {},
        series: [
          {
            name: "销量",
            type: "bar",
            data: [],
            label: {
              show: false,
              position: "top",
              formatter: '{@score}亿件'
            }
          },
          {
            name: "预估销量",
            type: "bar",
            data: [],
            label: {
              show: false,
              position: "top"
            }
          },
          {
            name: "趋势",
            type: "line",
            smooth: true,
            data: []
          },
          {
            type: "pie",
            center: ['80%', 40],
            radius: 30,
            // roseType: 'angle',
            data: []
          },
        ],
      });
    },
    getData() {
      this.myChart.showLoading()
      setTimeout(() => {
        unwarp(this.myChart).setOption({
          xAxis: {
            data: ["2012","2013","2014","2015","2016","2017","2018","2019","2020","2021"]
          },
          series: [
            {
              data: [1, 13, 47, 34, 5, 13, 32, 40, 15, 22]
            },
            {
              data: [3, 15, 43, 30, 8, 14, 32, 42, 14, 23]
            },
            {
              data: [2, 3, 40, 16, 14, 16, 34, 5, 12, 24]
            },
            {
              data: [
                {name: "服装", value: 10},
                {name: "食品", value: 15},
                {name: "日用品", value: 30},
                {name: "数码", value: 20},
                {name: "家电", value: 25},
              ]
            },
          ],
        })
        this.myChart.hideLoading()
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../base-scss';
</style>
